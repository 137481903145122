import * as React from 'react';
import { graphql, PageProps, useStaticQuery } from 'gatsby';
import { LayoutProvider, Wrapper } from '../components/layout';
import { cannonStyled } from '../theme';
import { CannonBreadcrumbs } from '../atoms';
import { ResponsiveContainer } from '../atoms/Container';
import { Blog } from '../components/sections';
import { useTableOrMobile } from '../hooks';
import { CannonSafeThemeT } from '../theme/CannonSafesTheme';
import { IFileImage } from '../@types';

const query = graphql`
  query {
    allStrapiBlogEntries (sort: {fields: created_at, order: DESC}) {
      nodes {
        id
        created_at
        mainArticle
        homeBtnText
        showInHome
        title
        blogEntries
        blogCategory {
          Name
        }
        previewText
        slug
        bodyText
        strapiId
        mainMedia {
          alternativeText
          ext
          hash
          url
          formats {
            large {
              ext
              hash
              url
            }
            medium {
              ext
              hash
              url
            }
            small {
              ext
              hash
              url
            }
            thumbnail {
              ext
              hash
              url
            }
          }
        }
      }
    }
  }
`;

/* Strapi Data */
export interface IBlog {
  allStrapiBlogEntries: {
    nodes: Array<IAllBlogs>;
  };
}

export interface IAllBlogs {
  blogEntries?: any;
  homeBtnText?: string;
  showInHome?: boolean;
  title: string;
  previewText: string;
  slug: string;
  previewImage: IFileImage;
  mainArticle: boolean;
  created_at?: string;
  mainMedia: IFileImage;
  blogCategory: {
    Name: string;
    id?: string;
  };
  bodyText?: string;
}
/* End of Strapi Data  */
interface BlogComponentProps {
  isMobile?: boolean;
  $theme?: CannonSafeThemeT;
}

const MainWrapper = cannonStyled(Wrapper, (props: BlogComponentProps) => ({
  display: 'flex',
  maxWidth: '1280px',
  width: props.isMobile ? '100%' : '95%',
  justifyContent: 'center',
  padding: props.isMobile ? '0 0' : '80px 0',
  margin: '0 auto',
  overflowX: 'hidden',
  textAlign: 'center',
  paddingBottom: '120px',
}));

const IndexPage: React.FC<PageProps> = (props: PageProps) => {
  const [isMobile, isTablet] = useTableOrMobile();

  const data = useStaticQuery<IBlog>(query);

  return (
    <LayoutProvider seo locationSearch={props.location.search}>
      <MainWrapper isMobile={isMobile} noScroll>
        <ResponsiveContainer $style={{ alignItems: 'flex-start' }}>
          <Blog allStrapiBlogEntries={data.allStrapiBlogEntries} />
        </ResponsiveContainer>
      </MainWrapper>
    </LayoutProvider>
  );
};

export default IndexPage;
